import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdutosService } from 'src/app/services/produtos.service';
@Component({
  selector: 'app-produto-categoria-body',
  templateUrl: './produto-categoria-body.component.html',
  styleUrls: ['./produto-categoria-body.component.css']
})
export class ProdutoCategoriaBodyComponent implements OnInit {
  produtos: any = []
  produtosFilter: any = []
  subCategorias: any = []
  categoria: any = { name: '' }
  @Input() config: any = {}
  loadding = true

  constructor(
    private produtoService: ProdutosService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let id = params.get('id');
        this.getProdutosPorCategoria(id)
        this.getCategoria(id)
        this.getSubCategorias(id)

      });
  }

  getProdutosPorCategoria(id) {
    this.produtoService.getProdutosPorCategoria(id).subscribe(
      data => {
        console.log(data)
        this.produtos = this.produtosFilter = data
      }, err => {
      }
    )
  }

  getCategoria(id) {
    this.produtoService.getCategoria(id).subscribe(
      data => {
        this.categoria = data
      }, err => {
      }
    )
  }

  getSubCategorias(id) {
    this.produtoService.getSubCategoria(id).subscribe(
      data => {
        this.subCategorias = data
        console.log(data)
        this.loadding = false

      }, err => {
      }
    )
  }

  filtraSub(s) {
    let temp = []
    this.produtosFilter.map((p) => {
      console.log(p)
      if (p.sub_categoria) {
        if (p.sub_categoria.id == s.id) {
          temp.push(p)
        }
      }
    })
    this.produtos = temp
  }
}
