<div class="cart_section" style="margin-top: -60px;">
    <div class="col-12">
        <div class="row">
            <div class="col-lg-10 offset-lg-1">
                <div class="cart_container">

                    <h3>Realize seu cadastro :)
                        <a class="btn btn-info" [routerLink]="['/login']">Já sou cadastrado</a>
                    </h3>
                    <form [formGroup]="clientForm" (submit)="salvarCliente()">
                        <div class="row">

                            <div class="col-lg-8 col-md-12 col-sm-12">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Nome *</span>
                                            <input formControlName="nome" class="form-control" autofocus type="text">
                                            <p *ngIf="clientForm.controls['nome'].invalid && clientForm.controls['nome'].errors.required"
                                                class="text-danger alert2">*Informe o nome</p>

                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Sobre nome *</span>
                                            <input formControlName="sobre_nome" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['sobre_nome'].invalid && clientForm.controls['sobre_nome'].errors.required"
                                                class="text-danger alert2">*Informe o sobre nome</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Telefone *</span>

                                            <input mask="00 00000-0000" formControlName="telefone" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['telefone'].invalid && clientForm.controls['telefone'].errors.required"
                                                class="text-danger alert2">*Informe o telefone</p>
                                            <p *ngIf="clientForm.controls['telefone'].invalid && !clientForm.controls['telefone'].errors.required"
                                                class="text-danger alert2">*Minimo de 11 caracteres</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="checkout__input">
                                            <span>Email *</span>
                                            <input formControlName="email" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['email'].invalid && clientForm.controls['email'].errors.required"
                                                class="text-danger alert2">*Informe o email</p>
                                            <p *ngIf="clientForm.controls['email'].invalid && clientForm.controls['email'].errors.email"
                                                class="text-danger alert2">Email inválido</p>

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-sm-4">
                                        <div class="checkout__input">
                                            <span>Senha *</span>
                                            <input formControlName="senha" class="form-control" name="senha"
                                                type="password">
                                            <p *ngIf="clientForm.controls['senha'].invalid && clientForm.controls['senha'].errors.required"
                                                class="text-danger alert2">*Informe a senha</p>
                                            <p *ngIf="clientForm.controls['senha'].invalid && !clientForm.controls['senha'].errors.required"
                                                class="text-danger alert2">*Minimo de 4 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-4 col-sm-4">
                                        <div class="checkout__input">
                                            <span>Tipo Doc. *</span>
                                            <select formControlName="tp_doc" id="tp_doc" (change)="selectDoc()"
                                                class="form-control">
                                                <option value="cpf">CPF</option>
                                                <option value="cnpj">CNPJ</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="tpDoc == 'cpf'">
                                        <div class="checkout__input">
                                            <span>CPF *</span>
                                            <input formControlName="cpf" mask="000.000.000-00" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cpf'].invalid && clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Informe o CPF</p>
                                            <p *ngIf="clientForm.controls['cpf'].invalid && !clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Minimo de 11 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="clientForm.value.tp_doc == 'cnpj'">
                                        <div class="checkout__input">
                                            <span>CNPJ *</span>
                                            <input formControlName="cpf" mask="00.000.000/0000-00" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cpf'].invalid && clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Informe o CNPJ</p>
                                            <p *ngIf="clientForm.controls['cpf'].invalid && !clientForm.controls['cpf'].errors.required"
                                                class="text-danger alert2">*Minimo de 14 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-5 col-8 col-sm-8" *ngIf="clientForm.value.tp_doc == 'cnpj'">
                                        <div class="checkout__input">
                                            <span>IE *</span>
                                            <input formControlName="ie" class="form-control" type="text">

                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-9 col-9 col-sm-9">
                                        <div class="checkout__input">
                                            <span>Rua *</span>
                                            <input formControlName="rua" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['rua'].invalid && clientForm.controls['rua'].errors.required"
                                                class="text-danger alert2">*Informe o rua</p>
                                            <p *ngIf="clientForm.controls['rua'].invalid && !clientForm.controls['rua'].errors.required"
                                                class="text-danger alert2">*Minimo de 5 caracteres</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-3 col-sm-3">
                                        <div class="checkout__input">
                                            <span>Nº *</span>
                                            <input formControlName="numero" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['numero'].invalid && clientForm.controls['numero'].errors.required"
                                                class="text-danger alert2">*Informe o numero</p>
                                            <p *ngIf="clientForm.controls['numero'].invalid && !clientForm.controls['numero'].errors.required"
                                                class="text-danger alert2">*Minimo de 1 caracter</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-6">
                                        <div class="checkout__input">
                                            <span>Bairro *</span>
                                            <input formControlName="bairro" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['bairro'].invalid && clientForm.controls['bairro'].errors.required"
                                                class="text-danger alert2">*Informe o bairro</p>
                                            <p *ngIf="clientForm.controls['bairro'].invalid && !clientForm.controls['bairro'].errors.required"
                                                class="text-danger alert2">*Minimo de 3 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-6">
                                        <div class="checkout__input">
                                            <span>CEP *</span>
                                            <input formControlName="cep" mask="00000-000" class="form-control"
                                                type="text">
                                            <p *ngIf="clientForm.controls['cep'].invalid && clientForm.controls['cep'].errors.required"
                                                class="text-danger alert2">*Informe o CEP</p>
                                            <p *ngIf="clientForm.controls['cep'].invalid && !clientForm.controls['cep'].errors.required"
                                                class="text-danger alert2">*Minimo de 8 caracteres</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">

                                    <div class="col-lg-6 col-8">
                                        <div class="checkout__input">
                                            <span>Cidade *</span>
                                            <input formControlName="cidade" class="form-control" type="text">
                                            <p *ngIf="clientForm.controls['cidade'].invalid && clientForm.controls['cidade'].errors.required"
                                                class="text-danger alert2">*Informe o cidade</p>
                                            <p *ngIf="clientForm.controls['cidade'].invalid && !clientForm.controls['cidade'].errors.required"
                                                class="text-danger alert2">*Minimo de 2 caracteres</p>
                                        </div>
                                    </div>

                                    <div class="col-lg-2 col-4">
                                        <div class="checkout__input">
                                            <span>UF *</span>
                                            <input formControlName="uf" mask="AA" class="form-control" data-mask="AA"
                                                data-mask-reverse="true" type="text">
                                            <p *ngIf="clientForm.controls['uf'].invalid && clientForm.controls['uf'].errors.required"
                                                class="text-danger alert2">*Informe a UF</p>
                                            <p *ngIf="clientForm.controls['uf'].invalid && !clientForm.controls['uf'].errors.required"
                                                class="text-danger alert2">*Minimo de 2 caracteres</p>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">
                                        <div class="checkout__input">
                                            <span>Complemento </span>
                                            <input formControlName="complemento" class="form-control" type="text">
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="row">
                                    <div class="col-lg-12">
                                        <div class="checkout__input">
                                            <span>Observação </span>
                                            <input class="form-control" type="text"
                                                placeholder="Observação sobre entrega por exemplo">
                                        </div>
                                    </div>
                                </div> -->

                            </div>

                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="cart_container">
                                    <div class="cart_items">

                                        <ul class="cart_list">

                                            <li class="cart_item clearfix">
                                                <div class="cart_title">Seu Pedido</div>

                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 80%;">Produto</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr *ngFor="let p of produtos">
                                                            <td>
                                                                {{p.name}}
                                                                <strong
                                                                    *ngIf="p.type == 'variable'">{{p.variacao.name}}</strong>

                                                            </td>
                                                            <td>
                                                                <div class="cart_item_text" *ngIf="p.type == 'single'">
                                                                    {{ p.valor_ecommerce | currency:'BRL' }}
                                                                </div>
                                                                <div class="cart_item_text" *ngIf="p.type == 'variable'">
                                                                    {{ p.variacao.sell_price_inc_tax | currency:'BRL' }}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <hr>

                                                <div class="checkout__order__total">Total
                                                    <span>
                                                        <strong>
                                                            {{ total | currency:'BRL' }}
                                                        </strong>
                                                    </span>
                                                </div>

                                                <button [style.background]="config.cor_btn" class="btn"
                                                    style="width: 100%; color: #fff;" type="submit"
                                                    [disabled]="!clientForm.valid">
                                                    Salvar
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>