import { Component, Input, OnInit } from '@angular/core';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-produtos-destaque',
  templateUrl: './produtos-destaque.component.html',
  styleUrls: ['./produtos-destaque.component.css']
})
export class ProdutosDestaqueComponent implements OnInit {

  produtos: any = []
  produtoRand: any = { image_url: '' }
  @Input() config: any = {}
  loadding = true

  constructor(
    private produtoService: ProdutosService
  ) { }

  ngOnInit(): void {
    this.getProdutos();
  }

  getProdutos() {
    this.produtoService.getProdutosDestaques().subscribe(
      data => {
        console.log(data)
        this.produtos = data
        // let rndInt = Math.floor(Math.random() * this.produtos.length); 
        let min = Math.ceil(0);
        let max = Math.floor(this.produtos.length);
        let rndInt = Math.floor(Math.random() * (max - min)) + min;;

        this.produtoRand = data[rndInt]
        this.loadding = false
      },
      err => {
      }
    )
  }

}
