import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {

  SERVER_URL = ''

  constructor(private http: HttpClient) {
    this.SERVER_URL = environment.url
  }

  public itens(ids):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/carrinho/itens', { headers: reqHeader, params: {ids: ids} });
  }

  public salvarPedido(data) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/carrinho/salvarPedido', {data: data}, { headers: reqHeader });
  }

  public processarPagamentoCartao(data) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/carrinho/processarPagamentoCartao', {data: data}, { headers: reqHeader });
  }

  public processarPagamentoBoleto(data) {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/carrinho/processarPagamentoBoleto', {data: data}, { headers: reqHeader });
  }

  public processarPagamentoPix(data):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.post(this.SERVER_URL + '/api/carrinho/processarPagamentoPix', {data: data}, { headers: reqHeader });
  }

  public getStatusPix(token):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/carrinho/getStatusPix', { headers: reqHeader, params: {token: token} });
  }

  public getPedido(token):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/carrinho/getPedido', { headers: reqHeader, params: {token: token} });
  }

  public getCupom(codigo, cliente):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/carrinho/getCupom', { headers: reqHeader, params: {codigo: codigo, cliente: cliente} });
  }

  public calcularFrete(cep, cart):any {
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${environment.token}`
    });
    return this.http.get(this.SERVER_URL + '/api/carrinho/calcularFrete', { headers: reqHeader, params: {cep: cep, cart: cart} });
  }

}
