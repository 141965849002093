<div class="container" *ngIf="loadding">
    <app-loader></app-loader>
</div>
<div class="container" *ngIf="loadding == false">
    <div class="contact_form">

        <div class="checkout__form">
            <h4>Selecione o endereço de entrega </h4>

            <div class="row">
                <div class="col-lg-8 col-md-6">
                    <div class="row">
                        <div class="col-12" style="margin-bottom: 10px;">
                            <button [style.background]="config.cor_btn" type="button" data-toggle="modal"
                                data-target="#modal-endereco" class="btn btn-lg">
                                <i class="fa fa-plus"></i> Novo Endereço
                            </button>
                        </div>

                        <div class="col-12" style="border-bottom: 1px solid #000;margin-bottom: 10px; margin-top: 10px;"
                            *ngFor="let p of cliente.enderecos; let first = first">

                            <label class="end">{{p.rua}}, {{p.numero}} - {{p.bairro}}</label>
                            <p>{{p.cidade}} ({{p.uf}}) {{p.cep}}</p>

                            <input [checked]="first && tipoFrete == 'sedex'" (click)="selectFrete(p, 'sedex')"
                                id="sedex" type="radio" value="{{p}}" name="endereco"> SEDEX R$ {{p.preco_sedex}}
                            - entrega em {{p.prazo_sedex}} dias úteis
                            <br>
                            <input [checked]="first && tipoFrete == 'pac'" (click)="selectFrete(p, 'pac')" id="pac"
                                type="radio" value="{{p}}" name="endereco"> PAC R$ {{p.preco}} - entrega
                            em {{p.prazo}} dias úteis
                            <br>

                            <div *ngIf="p.frete_gratis">
                                <input [checked]="first && tipoFrete == 'gratis'" (click)="selectFrete(p, 'gratis')"
                                    id="pac" type="radio" value="{{p}}" name="endereco">
                                Frete grátis - entrega em {{p.prazo}} dias úteis
                            </div>

                            <div *ngIf="p.retirada">
                                <input [checked]="first && tipoFrete == 'retirada'" (click)="selectFrete(p, 'retirada')"
                                    id="pac" type="radio" value="{{p}}" name="endereco">
                                Irei retirar na loja
                            </div>
                            <br>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="contact_form_inputs d-flex">
                                <input type="text" class="form-control" (blur)='getCupom()' [(ngModel)]="cupom_desconto"
                                    placeholder="Cupom de desconto">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="cart_container">
                        <div class="cart_items" style="margin-top: 40px;">

                            <ul class="cart_list">

                                <li class="cart_item clearfix">
                                    <div class="cart_title">Seu Pedido</div>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width: 77%;">Produto</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr *ngFor="let p of produtos">
                                                <td>
                                                    {{p.quantidade}}x{{p.produto.nome}}
                                                </td>
                                                <td>
                                                    <div class="cart_item_text">
                                                        {{ p.valor | currency:'BRL' }}
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="checkout__order__subtotal">Frete
                                        <span>{{valorFrete | currency:'BRL' }}</span>
                                    </div>
                                    <div *ngIf="desconto > 0" class="checkout__order__subtotal">Desconto
                                        <span>{{desconto | currency:'BRL' }}</span>
                                    </div>

                                    <div class="checkout__order__total">Total
                                        <span>
                                            {{valorFrete+total | currency:'BRL' }}
                                        </span>
                                    </div>
                                    <hr>

                                    <button type="button" [disabled]="frete == null" [style.background]="config.cor_btn"
                                        (click)="salvarPedido()" class="btn btn-lg btn-block">
                                        Pronto
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br>

<div class="modal fade" id="modal-endereco" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" id="titulo">Novo Endereço</h5>
                <button type="button" class="close" #closebutton2 data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="enderedoForm" (submit)="salvarEndereco()">
                <div class="modal-body">
                    <div class="row">

                        <div class="col-lg-8 col-12">
                            <div class="checkout__input">
                                <label>Rua</label>
                                <input class="form-control" id="rua" formControlName="rua" type="text">
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="checkout__input">
                                <label>Nº</label>
                                <input class="form-control" formControlName="numero" type="text">
                            </div>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="checkout__input">
                                <label>Bairro</label>
                                <input class="form-control" formControlName="bairro" type="text">
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="checkout__input">
                                <label>CEP</label>
                                <input class="form-control" mask="00000-000" formControlName="cep" type="text">
                            </div>
                        </div>

                        <div class="col-lg-8 col-12">
                            <div class="checkout__input">
                                <label>Cidade</label>
                                <input class="form-control" formControlName="cidade" type="text">
                            </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="checkout__input">
                                <label>UF</label>
                                <select formControlName="uf" class="custom-select" style="margin-left: 0px;">
                                    <option value="{{u}}" *ngFor="let u of estados()">{{u}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="checkout__input">
                                <label>Complemento</label>
                                <input class="form-control" formControlName="complemento" type="text">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
                    <button [disabled]="!enderedoForm.valid" type="submit" class="btn btn-success">Salvar</button>
                </div>
            </form>
        </div>
    </div>
</div>