<!-- <div class="hero_social">
  <a *ngIf="config.link_facebook != ''" href="{{config.link_facebook}}">
    <i class="fa fa-facebook" aria-hidden="true"></i>
  </a>
  <a *ngIf="config.link_twiter != ''" href="{{config.link_twiter}}">
    <i class="fa fa-twitter" aria-hidden="true"></i>
  </a>
 
  <a *ngIf="config.link_instagram != ''" href="{{config.link_instagram}}">
    <i class="fa fa-instagram" aria-hidden="true"></i>
  </a>
</div> -->
<!-- <section class="slider_section" [style.background]="config.cor_fundo" *ngIf="carrossel.length > 0">
  <div id="customCarousel1" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div *ngFor="let c of carrossel; let first = first;" [ngClass]="first ? 'carousel-item active' : 'carousel-item'">
        <div class="container-fluid ">
          <div class="row">
            
            <div class="col-md-12">
              <div class="img-box">
                <img src="{{c.image_url}}" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ol class="carousel-indicators">
      <li [style.background-color]="config.cor_btn" *ngFor="let c of carrossel; let i = index" data-target="#customCarousel1" 
      data-slide-to="i" [class.active]="first"></li>
    </ol>
  </div>
</section> -->

<div id="cs" class="carousel slide" data-ride="carousel" data-interval="3000">
  <!-- Indicators -->

  <ul class="carousel-indicators">
    
    <!-- <li data-target="#demo" data-slide-to="0" class="active"></li>
    <li data-target="#demo" data-slide-to="1"></li> -->
    <li *ngFor="let c of carrossel; let i = index" data-target="#demo" data-slide-to="i" [class.active]="i == 0"></li>
  </ul>

  <!-- The slideshow -->
  <div class="carousel-inner">
    <div *ngFor="let c of carrossel; let first = first;" [ngClass]="first ? 'carousel-item active' : 'carousel-item'">
      <a *ngIf="c.link_acao != ''" href="{{c.link_acao}}" class="link-img">
        <img src="{{c.image_url}}">
      </a>
      <img *ngIf="c.link_acao == ''" src="{{c.image_url}}">
      <div class="carousel-caption d-none d-md-block">
        <h4 [style.color]="c.cor_titulo">{{c.titulo}}</h4>
        <h6 [style.color]="c.cor_descricao">{{c.descricao}}</h6>
        <a *ngIf="c.link_acao != '' && c.nome_botao != ''" [style.background-color]="c.cor_titulo" class="btn" href="{{c.link_acao}}">
          {{c.nome_botao}}
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="carousel-inner">
    <div class="carousel-item active">
      <a  href="">
      <img src="http://localhost:8000/ecommerce/carrossel/NxXkK6LDerahpt5qSZ3T.png">
      </a>
      <div class="carousel-caption d-none d-md-block">
       
      </div>
    </div>
    <div class="carousel-item active">
      <a  href="">
      <img src="http://localhost:8000/ecommerce/carrossel/XqqM1F0mT66h5lD9DB1v.png">
      </a>
      <div class="carousel-caption d-none d-md-block">
       
      </div>
    </div>
  </div> -->

  <!-- Left and right controls -->
  <a class="carousel-control-prev" href="#cs" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" #nextbutton href="#cs" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>

</div>