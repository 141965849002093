import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CarrinhoService } from '../services/carrinho.service';
import { ConfigService } from '../services/config.service';
import { FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert';
import { LocalStorageService } from '../local-storage.service';

// declare function initMercadoPago(data);
declare var Mercadopago
@Component({
  selector: 'app-pagamento-body',
  templateUrl: './pagamento-body.component.html',
  styleUrls: ['./pagamento-body.component.css']
})
export class PagamentoBodyComponent implements OnInit {

  @Input() config: any = {}
  pedido: any = {
    total: 0
  }
  tipo = 'cartao'

  cartaoForm: any;
  pixForm: any;
  boletoForm: any;
  img = "";
  paymentMethodId = ''
  parcelas: any = [];
  token = '';
  loader = false
  pix = false;
  qrCode64 = ''
  copiaeCola = ''
  loadding = true
  valorTotal = 0

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private carrinhoService: CarrinhoService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let token = params.get('token');
        this.getPedido(token)
      });
    this.createFormCartao(null)
    this.createFormBoleto(null)
    this.createFormPix(null)
    this.configInit()
    this.verificaPix()
  }

  getPedido(token) {
    this.carrinhoService.getPedido(token).
      subscribe(
        data => {
          console.log(data)
          this.pedido = data
          this.createFormCartao(data)
          this.createFormPix(data)
          this.createFormBoleto(data)
          this.loadding = false
          this.valorTotal = data.total_cartao
        },
        err => {
          console.log(err)
        }
      )
  }

  verificaPix() {
    setInterval(() => {
      if (this.pix) {
        this.getStatusPix()
      }
    }, 3000)
  }

  getStatusPix() {
    this.carrinhoService.getStatusPix(this.pedido.token)
      .subscribe(
        data => {
          if (data == "approved") {
            this.router.navigate(['/pedido-finalziado', this.pedido.token]);
          }
        },
        err => {
        }
      )
  }

  createFormCartao(data) {

    if (data == null) {
      this.cartaoForm = this.formBuilder.group({
        cardholderName: ['', [Validators.required]],
        docNumber: ['', [Validators.required]],
        email: ['', [Validators.required]],
        cardNumber: ['', [Validators.required]],
        installments: ['', [Validators.required]],
        cardExpirationMonth: ['', [Validators.required]],
        cardExpirationYear: ['', [Validators.required]],
        securityCode: ['', [Validators.required]],
        issuer: ['', [Validators.required]],
        transactionAmount: ['', [Validators.required]],
        description: ['', [Validators.required]],
        paymentMethodId: ['', [Validators.required]],
        docType: ['CPF', [Validators.required]],
      });
    } else {
      let cliente = data.cliente
      let tipo = cliente.cpf.length == 14 ? 'CPF' : 'CNPJ'
      console.log(data)
      this.cartaoForm = this.formBuilder.group({
        cardholderName: [cliente.nome + " " + cliente.sobre_nome, [Validators.required]],
        docNumber: [cliente.cpf, [Validators.required]],
        email: [cliente.email, [Validators.required]],
        cardNumber: ['', [Validators.required]],
        installments: ['', [Validators.required]],
        cardExpirationMonth: ['', [Validators.required]],
        cardExpirationYear: ['', [Validators.required]],
        securityCode: ['', [Validators.required]],
        issuer: ['', [Validators.required]],
        transactionAmount: [data.total_cartao, [Validators.required]],
        description: [data.descricao, [Validators.required]],
        paymentMethodId: ['', [Validators.required]],
        docType: [tipo, [Validators.required]],
      });
    }

    // this.cartaoForm = this.formBuilder.group({
    //   cardholderName: ['Marcos Mello', [Validators.required]],
    //   docNumber: ['09520985980', [Validators.required]],
    //   email: ['marcos_buenomello@hotmail.com', [Validators.required]],
    //   cardNumber: ['5031 4332 1540 6351', [Validators.required]],
    //   installments: ['', [Validators.required]],
    //   cardExpirationMonth: ['11', [Validators.required]],
    //   cardExpirationYear: ['25', [Validators.required]],
    //   securityCode: ['123', [Validators.required]],
    //   issuer: ['', [Validators.required]],
    //   transactionAmount: ['', [Validators.required]],
    //   description: ['teste de pagamento', [Validators.required]],
    //   paymentMethodId: ['', [Validators.required]],
    //   docType: ['CPF', [Validators.required]],
    // });
  }

  createFormPix(data) {
    if (data == null) {
      this.pixForm = this.formBuilder.group({
        payerFirstName: ['', [Validators.required]],
        payerLastName: ['', [Validators.required]],
        payerEmail: ['', [Validators.required]],
        docNumber: ['', [Validators.required]],
        docType: ['CPF', [Validators.required]],
        transactionAmount: ['', [Validators.required]],
        description: ['', [Validators.required]],
        paymentMethodId: ['', [Validators.required]]
      });
    } else {
      let cliente = data.cliente

      let tipo = cliente.cpf.length == 14 ? 'CPF' : 'CNPJ'
      this.pixForm = this.formBuilder.group({
        payerFirstName: [cliente.nome, [Validators.required]],
        payerLastName: [cliente.sobre_nome, [Validators.required]],
        payerEmail: [cliente.email, [Validators.required]],
        docNumber: [cliente.cpf, [Validators.required]],
        docType: [tipo, [Validators.required]],
        transactionAmount: [data.total_pix, [Validators.required]],
        description: [data.descricao, [Validators.required]],
        paymentMethodId: ['', [Validators.required]]
      });
    }
    // this.pixForm = this.formBuilder.group({
    //   payerFirstName: ['Marcos', [Validators.required]],
    //   payerLastName: ['Mello', [Validators.required]],
    //   payerEmail: ['marcos_buenomello@hotmail.com', [Validators.required]],
    //   docNumber: ['09520985980', [Validators.required]],
    //   docType: ['CPF', [Validators.required]],
    //   transactionAmount: ['', [Validators.required]],
    //   description: ['teste de pagamento', [Validators.required]],
    //   paymentMethodId: ['', [Validators.required]]
    // });
  }

  createFormBoleto(data) {
    if (data == null) {
      this.boletoForm = this.formBuilder.group({
        payerFirstName: ['', [Validators.required]],
        payerLastName: ['', [Validators.required]],
        payerEmail: ['', [Validators.required]],
        docNumber: ['', [Validators.required]],
        docType: ['CPF', [Validators.required]],
        transactionAmount: ['', [Validators.required]],
        description: ['', [Validators.required]],
        paymentMethodId: ['', [Validators.required]]
      });
    } else {
      let cliente = data.cliente

      let tipo = cliente.cpf.length == 14 ? 'CPF' : 'CNPJ'
      this.boletoForm = this.formBuilder.group({
        payerFirstName: [cliente.nome, [Validators.required]],
        payerLastName: [cliente.sobre_nome, [Validators.required]],
        payerEmail: [cliente.email, [Validators.required]],
        docNumber: [cliente.cpf, [Validators.required]],
        docType: [tipo, [Validators.required]],
        transactionAmount: [data.total_boleto, [Validators.required]],
        description: [data.descricao, [Validators.required]],
        paymentMethodId: ['', [Validators.required]]
      });
    }

    // this.boletoForm = this.formBuilder.group({
    //   payerFirstName: ['Marcos', [Validators.required]],
    //   payerLastName: ['Mello', [Validators.required]],
    //   payerEmail: ['marcos_buenomello@hotmail.com', [Validators.required]],
    //   docNumber: ['09520985980', [Validators.required]],
    //   docType: ['CPF', [Validators.required]],
    //   transactionAmount: ['', [Validators.required]],
    //   description: ['teste de pagamento', [Validators.required]],
    //   paymentMethodId: ['', [Validators.required]]
    // });
  }

  payCartao() {
    this.loader = true
    let value = this.cartaoForm.value;
    let $form = document.getElementById('paymentForm');

    Mercadopago.createToken($form, (status, response) => {

      if (status == 200) {
        this.token = response.id
        response.pedido_id = this.pedido.id
        response.description = this.cartaoForm.value.description
        response.paymentMethodId = this.paymentMethodId
        response.email = this.cartaoForm.value.email
        response.docType = this.cartaoForm.value.docType
        response.docNumber = this.cartaoForm.value.docNumber
        response.installments = this.cartaoForm.value.installments

        this.processarPagamento(response)
      } else {
        swal("Erro", "Erro ao gerar token de autenticação", "error")
        this.loader = false
      }

    })
  }

  payPix() {
    this.loader = true
    let data = this.pixForm.value
    data.pedido_id = this.pedido.id
    data.valor_total = this.pedido.total_pix
    console.log(data)
    this.carrinhoService.processarPagamentoPix(data)
      .subscribe(
        data => {
          this.loader = false
          swal("Sucesso", "Chave pix gerada", "success")
            .then(() => {
              this.localStorage.carrinhoClear()
              this.pix = true
              this.qrCode64 = data.qr_code_base64
              this.copiaeCola = data.qr_code
            })
        },
        err => {
          console.log(err)
          this.loader = false
          try {
            swal("Error", err.error, "error")
          } catch {
            swal("Erro", "Erro ao gerar chave pix para pagamento", "error")
          }
        }
      )

  }

  payBoleto() {
    this.loader = true
    let data = this.boletoForm.value
    data.pedido_id = this.pedido.id
    data.valor_total = this.pedido.total_boleto

    this.carrinhoService.processarPagamentoBoleto(data)
      .subscribe(
        data => {
          this.loader = false
          swal("Sucesso", "Boleto gerado", "success")
            .then(() => {
              this.localStorage.carrinhoClear()
              this.router.navigate(['/pedido-finalziado', this.pedido.token]);
            })
        },
        err => {
          this.loader = false
          try {
            swal("Error", err.error, "error")
          } catch {
            swal("Erro", "Erro ao finalizar pagamento", "error")

          }

        }
      )
  }

  processarPagamento(data) {
    data.valor_total = this.pedido.total_cartao

    this.carrinhoService.processarPagamentoCartao(data)
      .subscribe(
        data => {
          this.loader = false
          swal("Sucesso", "Pagamento aprovado, obirgado pela compra", "success")
            .then(() => {
              this.localStorage.carrinhoClear()
              this.router.navigate(['/pedido-finalziado', this.pedido.token]);
            })
        },
        err => {
          this.loader = false
          try {
            swal("Error", err.error, "error")
          } catch {
            swal("Erro", "Erro ao finalizar pagamento", "error")
          }
        }
      )
  }

  validacartao() {
    let cNumber = this.cartaoForm.value.cardNumber.replaceAll(" ", "")
    if (cNumber >= 6) {
      let cardNum6 = cNumber.substr(0, 6)
      Mercadopago.getPaymentMethod({
        "bin": cardNum6
      }, async (status, response) => {
        if (status == 200) {
          if (response[0]) {
            let paymentMethod = response[0];
            this.paymentMethodId = paymentMethod.id
            this.img = paymentMethod.secure_thumbnail
            this.getInstallments(cardNum6)
          }
        } else {
          // console.log(`payment method info error: ${response}`);
        }
      }, 500);
    }
  }

  getInstallments(bin) {

    Mercadopago.getInstallments({
      "bin": bin,
      "amount": parseFloat(this.pedido.valor_total)
    }, (status, response) => {

      if (status == 200) {
        if (response[0]) {

          this.parcelas = response[0].payer_costs
          this.cartaoForm.controls.installments.setValue(1)

        }
      }
    });
  }


  configInit() {
    this.configService.config()
      .subscribe(
        data => {

          this.initMercadoPago(data.mercadopago_public_key)
        },
        err => {
        }
      )
  }

  initMercadoPago(key) {
    Mercadopago.setPublishableKey(key)
    Mercadopago.getIdentificationTypes()
  }

  setTipoPagamento(tipo) {
    this.tipo = tipo
    console.log(tipo)
    if(tipo == 'pix'){
      this.valorTotal = this.pedido.total_pix
    }else if(tipo == 'cartao'){
      this.valorTotal = this.pedido.total_cartao
    }else if(tipo == 'boleto'){
      this.valorTotal = this.pedido.total_boleto
    }
  }

  copy() {
    try {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.copiaeCola;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      swal("Sucesso", "Código pix copado!!", "success")
    } catch {
      swal("Erro", "Erro ao copiar chave", "error")
    }
  }
}
