import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProdutosService } from '../services/produtos.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Carrinho } from '../models/carrinho';
import { LocalStorageService } from '../local-storage.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';

declare function initImage2();

@Component({
  selector: 'app-produto-detalhe-body',
  templateUrl: './produto-detalhe-body.component.html',
  styleUrls: ['./produto-detalhe-body.component.css']
})
export class ProdutoDetalheBodyComponent implements OnInit {

  @Input() config: any = {}
  produto: any = {
    'name': '',
    'image_url': '',
    'category': {},
    'imagensAll': [],
    'variations' : []
  }
  
  prodForm: any;
  imgPrincipal = ''
  variacao = null
  valor_item = 0

  tipoProduto = "single"
  imagensVariacoes = []
  loadding = true
  constructor(
    private produtoService: ProdutosService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.createForm()
  }

  createForm() {
    this.prodForm = this.formBuilder.group({
      quantidade: [1, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        let id = params.get('id');
        this.getProdutosPorId(id)
        initImage2()
      });
  }

  getProdutosPorId(id) {
    console.log(id)
    let cliente = this.localStorage.getCliente()
    this.produtoService.getProdutosPorId(id, cliente != null ? cliente.token : null).subscribe(
      data => {
        this.produto = data
        this.valor_item = this.produto.valor
        console.log(data)
        this.imgPrincipal = this.produto.imagensAll[0]
        this.tipoProduto = this.produto.produto.grade == 1 ? 'variacao' : 'single'
        if(this.tipoProduto == 'variacao'){
          this.variacao = this.produto.variacoes[0]
          console.log(this.variacao)
          this.montaImagensVariacao()
          this.valor_item = this.variacao.valor
        }
        this.loadding = false
      }, err => {
        swal("Erro", "Produto não encontrado!!", "error")
        .then(() => {
          this.router.navigate(['/']);
        })
      }
    )
  }

  montaImagensVariacao(){
    this.imgPrincipal = this.produto.variacoes[0].image
    this.produto.variacoes.map((i) => {
      this.imagensVariacoes.push(i.image)
    })
  }

  salvar() {
    let cart: Carrinho = {
      id: this.tipoProduto == 'single' ?  this.produto.id : this.variacao.id,
      quantidade: this.prodForm.value.quantidade,
    }

    let sts = this.localStorage.setItemCarrinho(cart)
    if (sts) {
      swal("Sucesso", "Item adicionado ao carrinho", "success")
        .then(() => {
          this.router.navigate(['/carrinho']);
        })
    }
  }

  favorito(produto) {
    let cliente = this.localStorage.getCliente()
    if (cliente == null) {

    } else {
      this.produtoService.favorito(produto.id, cliente.token)
      .subscribe(
        data => {
          if(data == "inserido"){
            swal("Sucesso", "Produto adicionado a lista de favoritos", "success")
          }
          this.getProdutosPorId(produto.id)
        },
        err => {
          swal("Erro", "Algo deu errado", "error")
        }
      )
  
    }
  }

  selecionaVariacao(v){
    console.log(v)
    this.variacao = v
    this.valor_item = this.variacao.valor
  }

}
