import { Component, Input, OnInit } from '@angular/core';
import { ProdutosService } from '../services/produtos.service';

@Component({
  selector: 'app-novidades-body',
  templateUrl: './novidades-body.component.html',
  styleUrls: ['./novidades-body.component.css']
})
export class NovidadesBodyComponent implements OnInit {

  produtos: any = []
  @Input() config: any = {}

  constructor(
    private produtoService: ProdutosService,
  ) { }

  ngOnInit(): void {

    this.getProdutosNovos()
  }

  getProdutosNovos() {
    this.produtoService.getProdutosNovos().subscribe(
      data => {
        this.produtos = data
      }, err => {
      }
    )
  }

}
