<div class="container" *ngIf="loadding">
    <app-loader></app-loader>
</div>
<div class="container" *ngIf="!loadding">
    <div class="row">
        <div class="col-lg-10 offset-lg-1">
            <div class="cart_container">
                <div class="cart_title">Carrinho</div>
                <div class="cart_items">
                    <ul class="cart_list">

                        <li class="cart_item clearfix" *ngIf="produtos.length == 0">
                            <h4>Carrinho vazio</h4>
                        </li>

                        <li class="cart_item clearfix" *ngFor="let p of produtos">
                            <div class="cart_item_image"><img src="{{p.image_url}}" alt=""></div>
                            <div class="cart_item_info d-flex flex-md-row flex-column justify-content-between">
                                <div class="cart_item_name cart_info_col">
                                    <div class="cart_item_title">Produto</div>
                                    <div class="cart_item_text">{{p.produto.nome}} 
                                        <strong *ngIf="p.produto.grade">{{p.produto.str_grade}}</strong>
                                    </div>
                                </div>

                                <div class="cart_item_quantity cart_info_col">
                                    <div class="cart_item_title">Quantidade</div>
                                    <!-- <div class="cart_item_text">{{p.quantidade}}</div> -->
                                    <div class="cart_item_text">
                                        <input (keyup)="valueChange(p, $event)" style="width: 80px"
                                            class="qtd form-control" type="text" value="{{p.quantidade}}">
                                    </div>
                                </div>
                                <div class="cart_item_price cart_info_col">
                                    <div class="cart_item_title">Valor unitário</div>
                                    <div class="cart_item_text">
                                        {{ p.valor | currency:'BRL' }}
                                    </div>
                                    
                                </div>
                                <div class="cart_item_total cart_info_col">
                                    <div class="cart_item_title">SubTotal</div>
                                    <div class="cart_item_text">
                                        {{ (p.valor*p.quantidade) | currency:'BRL' }}
                                    </div>
                                    
                                </div>

                                <div class="cart_item_total cart_info_col">
                                    <div class="cart_item_title"></div>
                                    <div class="cart_item_text">
                                        <button (click)="deleteItem(p)" class="btn btn-danger">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="cart_buttons" style="margin-top: 5px;">

                        <button [disabled]="produtos.length == 0" (click)="montarCarrinho()" [style.background]="config.cor_fundo" style="color: #fff;"
                            type="button" class="btn">
                            <i class="fa fa-retweet"></i>
                            ATUALIZAR CARRINHO
                        </button>
                    </div>
                </div>

                <div class="row">
                    <h3 class="col-12">Calcular Frete</h3>
                    <div class="col-lg-3 col-sm-4">
                        <div class="checkout__input">
                            <span>CEP</span>
                            <input [(ngModel)]="cep" mask="00000-000" class="form-control" type="text">
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-4">
                        <button [disabled]="produtos.length == 0 || loader || cep.length < 8" (click)="calcular()" class="btn btn-cep" [style.background]="config.cor_btn">
                            Calcular
                            <div *ngIf="loader" class="spinner-border" role="status">
                            </div>
                        </button>
                    </div>
                </div>

                <div style="border-bottom: 1px solid #000;margin-bottom: 10px; margin-top: 10px;"
                    *ngIf="frete != null">

                    <input (click)="selectFrete('sedex')" id="sedex" type="radio" name="endereco">
                    SEDEX R$ {{frete.preco_sedex}}
                    - entrega em {{frete.prazo_sedex}} dias úteis
                    <br>
                    <input (click)="selectFrete('pac')" id="pac" type="radio" value="{{p}}" name="endereco"> PAC R$
                    {{frete.preco}} - entrega
                    em {{frete.prazo}} dias úteis
                    <br>

                    <div *ngIf="frete.frete_gratis">
                        <input (click)="selectFrete('gratis')" id="pac" type="radio" value="{{p}}" name="endereco">
                        Frete grátis - entrega em {{frete.prazo}} dias úteis
                    </div>

                    <div *ngIf="frete.retirada">
                        <input (click)="selectFrete('retirada')" id="" type="radio" value="{{p}}" name="endereco">
                        Irei retirar na loja
                    </div>
                    <br>
                </div>

                <!-- Order Total -->
                <div class="order_total">
                    <div class="order_total_content text-md-right">
                        <div class="order_total_title">Total do pedido:</div>
                        <div class="order_total_amount">
                            {{ total | currency:'BRL' }}
                        </div>
                    </div>
                </div>


                <div class="cart_buttons">
                    <a [routerLink]="['/']" class="btn btn-outline-primary btn-lg col-12 col-sm-6 col-lg-4">Continuar
                        comprando</a>

                    <button [disabled]="produtos.length == 0" [routerLink]="['/checkout', tipoFrete]"
                        [style.background]="config.cor_btn" type="button"
                        class="button cart_button_checkout col-12 col-sm-4">Finalizar</button>
                </div>
            </div>
        </div>
    </div>
</div>
<br>