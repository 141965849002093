<div class="container" *ngIf="loadding">
    <app-loader></app-loader>
</div>
<div class="container" *ngIf="!loadding">
    <div class="row">
        <div class="col-lg-10 offset-lg-1">
            <div class="cart_container">

                <div [innerHTML]="pedido.mensagem_agradecimento | safe: 'html'">
                </div>

                <a *ngIf="pedido.link_boleto != ''" href="{{pedido.link_boleto }}" target="_blank" class="btn"
                    [style.background]="config.cor_btn" style="color: #fff;">
                    <i class="fa fa-print"></i>
                    Imprimir Boleto
                </a>

                <h3>Método de pagamento: <strong [style.color]="config.cor_fundo">{{pedido.forma_pagamento}}</strong>
                </h3>

                <div class="cart_items">

                    <div class="cart_title">Itens da compra</div>

                    <ul class="cart_list">

                        <li class="cart_item clearfix" *ngFor="let i of pedido.itens">
                            <div class="cart_item_image">
                                <img src="{{i.produto.galeria[0].image_url}}" alt="">
                            </div>
                            <div class="cart_item_info d-flex flex-md-row flex-column justify-content-between">
                                <div class="cart_item_name cart_info_col">
                                    <div class="cart_item_title">Produto</div>
                                    <div class="cart_item_text">{{i.produto.produto.nome}}
                                        <div *ngIf="i.produto.produto.grade">
                                            {{i.produto.produto.str_grade}}
                                        </div>
                                    </div>
                                </div>

                                <div class="cart_item_quantity cart_info_col">
                                    <div class="cart_item_title">Quantidade</div>
                                    <div class="cart_item_text">
                                        {{i.quantidade}}
                                    </div>
                                </div>
                                <div class="cart_item_price cart_info_col">
                                    <div class="cart_item_title">Valor unitário</div>
                                    <div class="cart_item_text">
                                        <div>
                                            {{ i.produto.valor | currency:'BRL' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="cart_item_total cart_info_col">
                                    <div class="cart_item_title">SubTotal</div>
                                    <div class="cart_item_text">
                                        <div>
                                            {{ i.produto.valor * i.quantidade| currency:'BRL' }}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- Order Total -->

                <div class="order_total">
                    <div class="order_total_content text-md-right">
                        <div class="order_total_title">Frete:</div>
                        <div class="order_total_amount">
                            {{ pedido.valor_frete | currency:'BRL' }}
                        </div>
                    </div>
                </div>

                <div class="order_total">
                    <div class="order_total_content text-md-right">
                        <div class="order_total_title">Total do pedido:</div>
                        <div class="order_total_amount">
                            {{ pedido.valor_total | currency:'BRL' }}
                        </div>
                    </div>
                </div>


                <div class="cart_items">

                    <div class="cart_title">Endereço de entrega</div>

                    <ul class="cart_list">
                        <li class="cart_item clearfix">
                            <h3>{{pedido.endereco.rua}}, {{pedido.endereco.numero}} -
                                {{pedido.endereco.bairro}}</h3>
                            <h3>{{pedido.endereco.cidade}} ({{pedido.endereco.uf}})</h3>
                            <h3>{{pedido.endereco.cep}}</h3>
                        </li>
                    </ul>
                </div>
            </div>


        </div>
    </div>
</div>
<br>