<div class="container" *ngIf="loadding">
    <app-loader></app-loader>
</div>
<div class="single_product" *ngIf="!loadding">
    <div class="container">
        <div class="row">

            <!-- Images -->
            <div class="col-lg-2 order-lg-1 order-2" *ngIf="tipoProduto != 'variacao'">
                <ul class="image_list">
                    <!-- <li data-image="{{produto.image_url}}"><img src="{{produto.image_url}}" alt=""></li> -->
                    <li *ngFor="let i of produto.imagensAll" data-image="{{i}}"><img src="{{i}}" alt=""></li>
                </ul>
            </div>

            <div class="col-lg-2 order-lg-1 order-2" *ngIf="tipoProduto == 'variacao'">
                <ul class="image_list">
                    <!-- <li data-image="{{produto.image_url}}"><img src="{{produto.image_url}}" alt=""></li> -->
                    <li *ngFor="let i of imagensVariacoes" data-image="{{i}}"><img src="{{i}}" alt=""></li>
                </ul>
            </div>

            <!-- Selected Image -->
            <div class="col-lg-5 order-lg-2 order-1">
                <div class="image_selected"><img src="{{imgPrincipal}}" alt=""></div>
            </div>

            <!-- Description -->
            <div class="col-lg-5 order-3">
                <div class="product_description">
                    <div class="product_category">{{produto.categoria ? produto.categoria.nome : ''}}</div>
                    <div class="product_name">{{produto.produto.nome}}</div>
                    <div style="height: 100px;" class="text-truncate">
                        <div class="product_text" [innerHTML]="produto.descricao | safe: 'html'">
                        </div>
                    </div>
                    <div class="order_info d-flex flex-row">
                        <form [formGroup]="prodForm" (submit)="salvar()">
                            <div class="clearfix" style="z-index: 1000;">

                                <ul class="product_color w-100" *ngIf="tipoProduto == 'variacao'">

                                    <li>
                                        <span>Variação: </span>
                                        <div class="color_mark_container" style="float: left; margin-left: 50px;">
                                            <div id="selected_color" class="" *ngIf="variacao != null">
                                                {{variacao.variacao}}
                                            </div>
                                        </div>
                                        <div class="color_dropdown_button"><i class="fa fa-chevron-down"></i></div>
                                        <ul class="color_list">
                                            <li *ngFor="let v of produto.variacoes">
                                                <div (click)="selecionaVariacao(v)">
                                                    {{v.variacao}}
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <!-- Product Quantity -->
                                <div class="product_quantity clearfix w-100 mt-2">
                                    <span>Quantidade: </span>
                                    <input mask="000" id="quantity_input" formControlName="quantidade">
                                    <!-- <div class="quantity_buttons">
                                        <div id="quantity_inc_button" class="quantity_inc quantity_control"><i
                                                class="fa fa-chevron-up"></i></div>
                                        <div id="quantity_dec_button" class="quantity_dec quantity_control"><i
                                                class="fa fa-chevron-down"></i></div>
                                    </div> -->
                                </div>
                                <!-- Product Color -->
                                
                            </div>

                            <div class="product_price">
                                {{ valor_item | currency:'BRL' }}
                            </div>

                            <div *ngIf="produto.valor_pix > 0" class="box-desconto">
                                <span><img src="/assets/images/pix.png" />{{ produto.valor_pix | currency:'BRL' }} no PIX</span>
                            </div>

                            <div *ngIf="produto.valor_cartao > 0" class="box-desconto">
                                <span><img src="/assets/images/cartao.png" />{{ produto.valor_cartao | currency:'BRL' }} no Cartão de Crédito</span>
                            </div>

                            <div *ngIf="produto.valor_boleto > 0" class="box-desconto">
                                <span><img src="/assets/images/boleto.png" />{{ produto.valor_boleto | currency:'BRL' }} no Boleto</span>
                            </div>

                            <div class="button_container">
                                <button [style.background]="config.cor_btn" type="submit"
                                    class="button cart_button">Adicionar ao carrinho</button>
                                <div (click)="favorito(produto)" class="product_fav"><i class="fa fa-heart"
                                        [class.text-danger]="produto.favorito"></i></div>
                            </div>

                        </form>
                    </div>

                </div>

            </div>

        </div>
        <div class="product_text" [innerHTML]="produto.descricao | safe: 'html'">
        </div>
    </div>
</div>