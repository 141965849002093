<section class="feature_section layout_padding">
    <div class="container">
        <div class="heading_container">
            <h2>
                Categorias
            </h2>
        </div>
        <div class="row">

            <div class="col-sm-6 col-lg-3" *ngFor="let c of categorias">
                <div class="box" [routerLink]="['/categorias', c.id]">
                    <div class="img-box">
                        <img style="height: 60px;" src="{{c.image_url}}" alt="">
                    </div>
                    <div class="detail-box">
                        <h5>
                            {{c.nome}}
                        </h5>

                        <a [routerLink]="['/categorias', c.id]">
                            <span>
                                Produtos
                            </span>
                            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</section>